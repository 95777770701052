import { I18n } from 'i18n-js';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import mtz from 'moment-timezone';
import moment from 'moment';
import { changeLanguage } from 'i18next';
import { AuthState } from '@okta/okta-auth-js';
import { getProfileByEmail } from './kinshipApi';

// !INFO
// This is the new implementation for typing translation functions or locale files.
// It will help us to know what keys exist and are possible for translation files.

export type TranslationKey =
  | string
  | [string, { [key: string]: string | number }];

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return changeLanguage;
};

const defaultLocaleForApp = 'en-US';
const storedLanguageKey = 'storedLanguage';
const loadMomentLocale = async (locale: string) => {
  switch (locale) {
    case 'en-GB':
      await require('moment/locale/en-gb');
      break;
    case 'fr':
      await require('moment/locale/fr');
      break;
    case 'es':
      await require('moment/locale/es');
      break;
    case 'de':
      await require('moment/locale/de');
      break;
  }
};

const locales = {
  en: require('../locales/en-US.json'), // en is a fallback for en-US
  'en-US': require('../locales/en-US.json'),
  'en-GB': require('../locales/en-GB.json'),
  es: require('../locales/es-ES.json'),
  fr: require('../locales/fr-FR.json'),
  de: require('../locales/de-DE.json'),
};
export const availableLocales = Object.keys(locales);

const i18n = new I18n(locales);
i18n.defaultLocale = defaultLocaleForApp;
i18n.translations = locales;

export const translate = (key: TranslationKey) => {
  if (!key) {
    return '';
  }

  const array = Array.isArray(key);
  const translationKey = array ? key[0] : key;
  const translationParams = array ? key[1] : {};
  return i18n.t(translationKey, translationParams);
};

export const setCurrentLocale = async (locale: string) => {
  try {
    const validLocale = availableLocales.includes(locale);
    if (!validLocale) {
      throw new Error(`Invalid locale: ${locale}`);
    }
    setCurrentLocaleAction(locale);
  } catch (e) {
    setCurrentLocaleAction(defaultLocaleForApp);
  }
};

const setCurrentLocaleAction = async (locale: string) => {
  i18n.locale = locale;
  if (moment.locales().includes(locale.toLocaleLowerCase())) {
    await loadMomentLocale(locale);
    moment.locale(locale);
  } else {
    console.warn(`Locale data for ${locale} is not loaded in Moment.js`);
  }
  await localStorage.setItem('storedLanguageKey', locale);
  changeLanguage(locale);
  return localStorage.setItem('currentLocale', locale);
};

export const setCurrentTimezone = async (timezone: string) => {
  mtz.tz.setDefault(timezone);
  // check if date-fns has a timezone setting too
  return localStorage.setItem('currentTimeZone', timezone);
};

export const useCurrentLocale = () => localStorage.getItem('currentLocale');

export const useIsRTL = () => isRTL(localStorage.getItem('currentLocale'));

export const useLocalisation = () => {
  const currentLanguage = useCurrentLocale();

  const setup = async () => {
    let storedLanguage = await localStorage.getItem(storedLanguageKey);
    // adding this for Backward Compatibility, so we start moving the current language stored from 'en' to 'en-US'or 'en-GB', basically the correct format of the locale
    if (storedLanguage === 'en') {
      storedLanguage = getDeviceSystemLocale();
    }
    const language =
      storedLanguage || currentLanguage || i18n.locale || defaultLocaleForApp;
    setCurrentLocale(language);
  };

  useEffect(() => {
    setup();
  });
};

/**
 * Since we have two sources of truth for locale, and since both are required in loggedOff vs loggedIn Times.
 * We have added this selector here to get the right locale and warn the developers if locale is not matching.
 * @returns en-US | en-GB | ar | ...
 */
export const useCurrentLocaleSelector = (): string => {
  const defaultLocaleForApp = 'en-US';
  const systemLocale = localStorage.getItem('currentLocale') ?? navigator.language ?? defaultLocaleForApp;
  let profileLocale =  systemLocale;
  const profile = localStorage.getItem('profile');
  if(profile) {
    profileLocale = JSON.parse(profile)?.locale;
  } else {
    profileLocale = defaultLocaleForApp;
  }
  
  if (profileLocale !== systemLocale) {
    console.warn('Profile locale does not match system locale');
  }
  return profileLocale || systemLocale;
};

export const isRTL = (locale: string | null) =>
  [
    'ar',
    'arc',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ku',
    'ps',
    'ur',
    'yi',
  ].includes(locale ?? '');

/**
 * This method is used to determine the mobile device system locale.
 * it also converts it into the format we use in the app.
 * @returns en-US | en-GB | ar | ...
 */
export const getDeviceSystemLocale = () => {
  try {
    const locale = navigator.language;
    const currentDeviceLocale = locale.replace('_', '-');
    const validLocale = availableLocales.includes(currentDeviceLocale);
    console.log('getDeviceSystemLocale -> validLocale =>', validLocale, 'locale =>', locale, 'currentDeviceLocale =>', currentDeviceLocale);
    return validLocale ? currentDeviceLocale : defaultLocaleForApp;
  } catch (e) {
    return defaultLocaleForApp;
  }
};

export const changeLocaleToMatchProfile = (authState: AuthState | null) => {
  console.log({ authState });
  if(authState) {
    console.log('usersEmail =>', authState?.idToken?.claims?.email);
    const profile = getProfileByEmail(authState?.idToken?.claims?.email);
    profile.then((response: any) => {
      if(response?.data) {
        console.log('profileFull =>', response?.data);
        localStorage.setItem('profile', JSON.stringify(response?.data));
        changeLanguage(response?.data?.locale);
      }
    })
  }
};