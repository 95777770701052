import { useState } from "react";
import {
  petPatch,
  deleteAllergiesConditions,
} from "../../utils/back4AppApi";
import {
  KinshipPetProfile,
  MedicalRecord,
  OngoingCondition,
  Allergy,
} from "../../utils/kinshipTypes";
import {
  Table,
  TableBody,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Stack,
  TableContainer,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";

interface Props {
  record?: MedicalRecord;
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function Allergies({ 
    record,
    pet,
    onAction = () => {}, 
}: Props) {
  const [newAllergyName, setNewAllergyName] = useState("");
  const onAllergyAction = async (event: any, ...args: Array<string | null>) => {
    event.preventDefault();
    const actionType = args[0];
    const allergyArg = args[1];
    const actionHasConfirmDialogStep = args[2];
    if(actionHasConfirmDialogStep !== "NEEDS_CONFIRMATION") {
      if(actionType === "ADD") {
        const data = {
          allergies: [{
            allergyName: newAllergyName,
            petType: pet?.petType
          }]
        };
        const result = await petPatch(
          pet?.kinshipPetId,
          data
        );
        onAction(result);
      } else if(actionType === "DELETE") {
        const result = await deleteAllergiesConditions(
          pet?.kinshipPetId,
          {
            deletedAllergies: [
              {
                allergyName: allergyArg
              }
            ]
          },
        );
        onAction(result);
      }
    }
  };
  const [newCondition, setNewCondition] = useState("");
  const onConditionAction = async (event: any, ...args: Array<string | null>) => {
    event.preventDefault();
    const actionType = args[0];
    const value = args[1];
    const actionHasConfirmDialogStep = args[2];
    if(actionHasConfirmDialogStep !== "NEEDS_CONFIRMATION") {
      if(actionType === "ADD") {
        const data = {
          ongoingConditions: [{
            ongoingConditionName: newCondition,
            petType: pet?.petType
          }]
        };
        const result = await petPatch(
          pet?.kinshipPetId,
          data
        );
        onAction(result);
      } else if(actionType === "DELETE") {
        const result = await deleteAllergiesConditions(
          pet?.kinshipPetId,
          {
            deletedConditions: [
              {
                ongoingConditionName: value
              }
            ]
          },
        );
        onAction(result);
      }
  }}
  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
      {translate("allergies")}
      </Typography>
      <TableContainer sx={{ minWidth: 1200 }}>
        <Table sx={{width: "max-content"}}>
        <TableBody>
          {record?.allergies?.map((allergy: Allergy, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell align="left" colSpan={2}>
                    {allergy?.allergyName}
                </TableCell>
                <TableCell align="left" colSpan={2}>
                    <Stack direction="row" spacing={1}>
                    <IconButton
                        color="primary"
                        aria-label={translate('delete')}
                        onClick={(event) => onAllergyAction(event, "DELETE", allergy?.allergyName)}
                    >
                        <DeleteIcon />
                    </IconButton>
                    </Stack>
                </TableCell>
              </TableRow>
            );
            }
        )}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <TextField
                  id="newName"
                  name="newName"
                  label={translate('new_name')}
                  variant="outlined"
                  size="small"
                  value={newAllergyName}
                  onChange={(e) => setNewAllergyName(e.target.value)}
              />
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(event) => onAllergyAction(event, "ADD")}
                  >
                    {translate('add_allergy')}
                  </Button>
              </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant="h4"
          component="h4"
          sx={{ mb: 2, fontWeight: "medium" }}
        >
          {translate('ongoing_conditions')}
        </Typography>
        <TableContainer sx={{ minWidth: 1200 }}>
          <Table sx={{width: "max-content"}}>
            <TableBody>
              {record?.ongoingConditions?.map((condition: OngoingCondition, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" colSpan={2}>
                        {condition?.ongoingConditionName}
                    </TableCell>
                      <TableCell align="left" colSpan={2}>
                        <Stack direction="row" spacing={1}>
                          <IconButton
                            color="primary"
                            aria-label={translate('delete')}
                            onClick={(event) => onConditionAction(event, "DELETE", condition?.ongoingConditionName)}
                          >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell align="left" colSpan={2}>
                  <TextField
                    id="newName"
                    name="newName"
                    label={translate('new_name')}
                    variant="outlined"
                    size="small"
                    value={newCondition}
                    onChange={(e) => setNewCondition(e.target.value)}
                  />
                  </TableCell>
                  <TableCell align="left" colSpan={2}>
                    <Stack direction="row" spacing={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={(event) => onConditionAction(event, "ADD")}
                      >
                        {translate('add_condition')}
                      </Button>
                    </Stack>
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box>
 );
}
