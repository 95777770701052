
import { useCurrentLocaleSelector as getCurrentLocaleSelector } from './utils/localisation';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { Security } from '@okta/okta-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';

import App from './App';
import authClient from './auth';
import './index.css';
import reportWebVitals from './reportWebVitals';

import deDE from './locales/de-DE.json';
import enGB from './locales/en-GB.json';
import enUS from './locales/en-US.json';
import esES from './locales/es-ES.json';
import frFR from './locales/fr-FR.json';

// translation resources
const resources = {
  'de-DE': { translation: deDE },
  'en-GB': { translation: enGB },
  'en-US': { translation: enUS },
  'es-ES': { translation: esES },
  'fr-FR': { translation: frFR },
};

i18n.init({
  resources,
  lng: getCurrentLocaleSelector(),
  fallbackLng: 'en-US',
  debug: true,
  interpolation: {
    escapeValue: false,
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <AppWithAuth />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

function AppWithAuth() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };
  return (
    <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
