import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Divider, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { AppContext } from '../../App';
import { PetProfile } from '../../types';
import axiosInstance from '../../utils/axiosInstance';
import { translate } from '../../utils/localisation';

export function UserProfileView() {
  const [pets, setPets] = useState<PetProfile[]>([]);
  const [subData, setSubData] = useState();

  const appContext = useContext(AppContext);

  const { userId } = useParams();

  useEffect(() => {
    // fetch additional details for the user
    async function fetchExtraDetails() {
      const extraData = await axiosInstance.get(
        `/admin/user-profile/${userId}`
      );

      setPets(extraData.data.details?.pets);

      setSubData(extraData.data.details?.stripe_customer_info[0]);
    }
    fetchExtraDetails();
  }, [userId]);
  // todo if petId isn't found we need to fetch

  const userProfile = appContext.userProfiles.find(
    (user) => user.id === userId
  );

  const handleClick = (
    event: React.MouseEvent<unknown>,
    key: string,
    value: string
  ) => {
    // navigate to route component PetProfile of the petId
    switch (key) {
      case 'pack_subscription_id':
        window.location.href = `https://dashboard.stripe.com/test/subscriptions/${value}`;
        break;
      default:
        break;
    }
  };

  const handleDelete = (
    event: React.MouseEvent<unknown>,
    pet: any,
  ) => {
    if(pet.is_active) {
      if(window.confirm(`Are you sure you want to deactivate ${pet.name}?`)) {
        // deactivate the pet
        axiosInstance.delete(`/admin/pet-profile/${pet.id}`).then((res) => {
          console.log(res.data);
        })
      }
    }
  }

  // List of keys that have a clickable interaction ex linking to external website
  const clickIndicators = ['pack_subscription_id'];

  return (
    <>
      <Typography variant="h2" component="h2">
        {translate('user-profiles-selected-screen.title-text')}
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h3">
          {
            translate([
              'user-profiles-selected-screen.user-table-caption-text', { 
                var: `${userProfile?.first_name} ${userProfile?.last_name}`
            }])
          }
          </Typography>
          <TableContainer
            sx={{ maxHeight: 405 }}
            variant="outlined"
            elevation={3}
            component={Paper}
          >
            <Table
              sx={{ minWidth: 300 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{translate('attribute')}</TableCell>
                  <TableCell align="left">{translate('value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userProfile
                  ? generateTable(userProfile, clickIndicators, handleClick)
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3">{translate('user-profiles-selected-screen.subscription-table-caption-text')}</Typography>
          <TableContainer
            sx={{ maxHeight: 405 }}
            variant="outlined"
            elevation={3}
            component={Paper}
          >
            <Table
              sx={{ minWidth: 300 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{translate('attribute')}</TableCell>
                  <TableCell align="left">{translate('value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subData
                  ? generateTable(subData, clickIndicators, handleClick)
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {pets.length
          ? pets.map((pet: PetProfile) => {
              return (
                <Grid item xs={6}>
                  <Typography variant="h3">
                    {
                      translate([
                        'user-profiles-selected-screen.pet-table-caption-text', { 
                          var: `${pet?.name}`
                      }])
                    }
                    {pet.is_active && <Button onClick={ (event) => handleDelete(event, pet) }><DeleteIcon /></Button>}
                  </Typography>
                  <TableContainer
                    sx={{ maxHeight: 405 }}
                    variant="outlined"
                    elevation={3}
                    component={Paper}
                  >
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">{translate('attribute')}</TableCell>
                          <TableCell align="left">{translate('value')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {generateTable(pet, clickIndicators, handleClick)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              );
            })
          : null}
      </Grid>
    </>
  );
}

// {
//   "pets": [
//       {
//           "id": "1eb82f11-f0ea-451f-9003-6f5d918ba1c6",
//           "name": "asdf",
//           "animal_type": "DOG",
//           "gender": "MALE",
//           "size": null,
//           "acquisition_date": "2022-01-01T00:00:00.000Z",
//           "breed": "Mixed Breed",
//           "birth_date": "2021-06-01T00:00:00.000Z",
//           "age_group": null,
//           "wisdom_breed_id_1": null,
//           "wisdom_breed_id_2": null,
//           "wisdom_breed_id_3": null,
//           "wisdom_breed_id_4": null,
//           "is_first_pet": null,
//           "microchip_id": null,
//           "last_vet_visit": null,
//           "spayed_or_neutered": true,
//           "created_at": "2022-07-18T22:15:16.519Z",
//           "updated_at": "2022-07-18T22:15:18.302Z",
//           "acquisition_date_precision": "DAY",
//           "birth_date_precision": "MONTH",
//           "created_by_user_profile_id": "ce491b13-21fe-4344-9857-2db761f3c8ea",
//           "updated_by_user_profile_id": "ce491b13-21fe-4344-9857-2db761f3c8ea",
//           "spayed_or_neutered_date": null,
//           "is_active": true,
//           "kinship_pet_id": "d825b861-2213-4885-a877-c68c7910e75a",
//           "has_pack": false,
//           "pack_subscription_id": null
//       }
//   ],
//   "stripe_customer_info": [
//       {
//           "stripe_customer_id": "cus_M5Cd6CupYP7dVh",
//           "created_at": "2022-07-18T22:15:46.406Z",
//           "updated_at": "2022-07-18T22:15:46.406Z",
//           "user_profile_id": "ce491b13-21fe-4344-9857-2db761f3c8ea",
//           "data": null
//       }
//   ]
// }

const generateTable = (
  tableData: any,
  clickIndicators: string[],
  handleClick: any
) => {
  // const clickIndicators = [];
  return (
    <>
      {tableData
        ? Object.entries(tableData).map(([key, value]) => {
            const clickAble = !!clickIndicators.find(
              (indicator) => indicator === key && value
            );

            return (
              <TableRow
                key={key}
                hover={clickAble}
                onClick={
                  clickAble
                    ? (event) => handleClick(event, key, value)
                    : undefined
                }
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: '#e6e6e6',
                  },
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell align="left">{key}</TableCell>
                <TableCell align="left">
                  {String(value)}
                  {clickAble ? <OpenInNewIcon /> : null}
                </TableCell>
              </TableRow>
            );
          })
        : null}
    </>
  );
};
