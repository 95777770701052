import { api } from './apis';

export function getVaccineTypes() {
  return api.get('kin/content/v1/vaccines.json');
}

export function uploadDocument(petId, formData) {
  return api.post(`/document/documents/vet_records/${petId}`, formData);
}

export async function getProfileByEmail (
  email,
) {
  return await api.get(`kinshipId/users/email/${encodeURIComponent(email)}`, {
    params: {
      isFullProfile: true,
    },
  });
}