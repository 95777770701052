import { KinshipPetProfile, Activity, Behavior, CustomField, ActivitiesBehaviors } from "../../utils/kinshipTypes";
import { setActivitiesAndBehaviors, getActivities, getBehaviors } from "../../utils/back4AppApi";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Select,
  Stack,
  Grid,
} from "@mui/material";

interface Props {
  customFields?: CustomField[];
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function Activities({ customFields, pet, onAction = () => {} }: Props) {
  const [availableActivities, setAvailableActivities] = useState<ActivitiesBehaviors[]>([]);
  const [availableBehaviors, setAvailableBehaviors] = useState<ActivitiesBehaviors[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [behaviors, setBehaviors] = useState<Behavior[]>([]);
  const [newActivityName, setNewActivityName] = useState("");
  const [newBehavior, setNewBehavior] = useState("");
  const onAllergyAction = async (event: any, ...args: Array<string | null>) => {
    const actionType = args[0];
    const activityArg = args[1];
    let data = {};
    if(actionType === "ADD") {
      if (!newActivityName) {
        return;
      }
      if (activities?.find((activity) => activity.name === newActivityName)) {
        return;
      }
      data = {
        activities: [
          ...activities,
          { 
            name: newActivityName,
            species: pet?.petType
          }
        ]
      }
    } else if (actionType === "DELETE") {
      data = {
        activities: activities?.filter((activity) => activity.name !== activityArg)
      }
    }
    const result = await setActivitiesAndBehaviors(
      pet?.kinshipPetId,
      "activities",
      data
    );
    onAction(result);
  };
  const onBehaviorAction = async (event: any, ...args: Array<string | null>) => {
    const actionType = args[0];
    const arg = args[1];
    let data = {};
    if(actionType === "ADD") {
      if (!newBehavior) {
        return;
      }
      if (behaviors?.find((behavior) => behavior.name === newBehavior)) {
        return;
      }
      data = {
        behaviors: [
          ...behaviors,
          { 
            name: newBehavior,
            species: pet?.petType
          }
        ]
      }
    } else if (actionType === "DELETE") {
      data = {
        behaviors: behaviors?.filter((behavior) => behavior.name !== arg)
      }
    }
    const result = await setActivitiesAndBehaviors(
      pet?.kinshipPetId,
      "behaviors",
      data
    );
    onAction(result);
  };
  useEffect(() => {
    const stringyString = customFields?.find((f) => f.name === 'activities')?.value || "[]";
    const behaviorsString = customFields?.find((f) => f.name === 'behaviors')?.value || "[]";
    setActivities(JSON.parse(stringyString));
    setBehaviors(JSON.parse(behaviorsString));
    const fetchActivities = async () => {
      const { data } = await getActivities();
      const activitiesData = data.activities as Array<ActivitiesBehaviors>;
      setAvailableActivities(activitiesData);
    }
    fetchActivities();
    const fetchBehaviors = async () => {
      const { data } = await getBehaviors();
      console.log(data);
      const behaviorsData = data.behaviors as Array<ActivitiesBehaviors>;
      setAvailableBehaviors(behaviorsData);
    }
    fetchBehaviors();
  }, [customFields]);
  const activitiesMap = availableActivities.map((activity) => {
    return (
      <MenuItem key={activity.id} value={activity.activityName}>
        {activity.activityName}
      </MenuItem>
    );
  });
  activitiesMap.push(
    <MenuItem key="select" value="">
      {translate('select')}
    </MenuItem>
  );
  const behaviorsMap = availableBehaviors.map((behavior) => {
    return (
      <MenuItem key={behavior.id} value={behavior.behaviourTraitName}>
        {behavior.behaviourTraitName}
      </MenuItem>
    );
  });
  behaviorsMap.push(
    <MenuItem key="select" value="">
      {translate('select')}
    </MenuItem>
  );
  const mappedActivities = () => {
    return activities.map((activity, index: number) => {
      return (
        <TableRow key={index}>
          <TableCell align="left" colSpan={2}>
            {activity?.name}
          </TableCell>
          <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  aria-label={translate('delete')}
                  onClick={(event) => onAllergyAction(event, "DELETE", activity?.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
          </TableCell>
        </TableRow>
      );}
    );
  };
  const mappedBehaviors = () => {
    return behaviors.map((behavior, index: number) => {
      return (
        <TableRow key={index}>
          <TableCell align="left" colSpan={2}>
            {behavior?.name}
          </TableCell>
          <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  aria-label={translate('delete')}
                  onClick={(event) => onAllergyAction(event, "DELETE", behavior?.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
          </TableCell>
        </TableRow>
      );}
    );
  };
  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
        <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate("activities")}
      </Typography>
      <Table sx={{width: "max-content"}}>
      <TableBody>
        {mappedActivities()}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <Select
                displayEmpty
                value={newActivityName}
                onChange={(e) => setNewActivityName(e.target.value)}
                sx={{ height: 40 }}
              >
                {activitiesMap}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(event) => onAllergyAction(event, "ADD")}
                >
                  {translate('add_activity')}
                </Button>
              </Stack>
            </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Grid>
    <Grid item xs={6}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate("behaviors")}
      </Typography>
    <Table sx={{width: "max-content"}}>
      <TableBody>
        {mappedBehaviors()}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <Select
                displayEmpty
                value={newBehavior}
                onChange={(e) => setNewBehavior(e.target.value)}
                sx={{ height: 40 }}
              >
                {behaviorsMap}
              </Select>
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(event) => onBehaviorAction(event, "ADD")}
                >
                  {translate('add_behavior')}
                </Button>
              </Stack>
            </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Grid>
  </Grid>
</Box>
);
}
