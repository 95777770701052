import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../App';
import { translate } from '../../utils/localisation';

export function PetProfileView() {
  const appContext = useContext(AppContext);

  const { petId } = useParams();
  // todo if petId isn't found we need to fetch
  const petProfile = appContext.petProfiles.find((pet) => pet.id === petId);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    key: string,
    value: string
  ) => {
    // navigate to route component PetProfile of the petId
    switch (key) {
      case 'pack_subscription_id':
        window.location.href = `https://dashboard.stripe.com/test/subscriptions/${value}`;
        break;
      default:
        break;
    }
  };

  // List of keys that have a clickable interaction ex linking to external website
  const clickIndicators = ['pack_subscription_id'];

  return (
    <>
      <Typography variant="h2" component="h2">
       {translate('pet_info')}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate('attribute')}</TableCell>
              <TableCell align="left">{translate('value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {petProfile
              ? Object.entries(petProfile).map(([key, value]) => {
                  const clickAble = !!clickIndicators.find(
                    (indicator) => indicator === key && value
                  );
                  return (
                    <TableRow
                      key={key}
                      hover={clickAble}
                      onClick={
                        clickAble
                          ? (event) => handleClick(event, key, value)
                          : undefined
                      }
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#e6e6e6',
                        },
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="left">{key}</TableCell>
                      <TableCell align="left">
                        {value}
                        {clickAble ? <OpenInNewIcon /> : null}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
