import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import colors from '../utils/colors';

export const StyledButton = styled(Button)({
  borderWidth: '2px',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  color: colors.tealDark,
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});