import { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Typography, TextField } from "@mui/material";
import { translate } from "../../utils/localisation";

export function PetProfileSearch() {
  const navigate = useNavigate();
  const [id, setId] = useState("");

  const onSubmit = (event: any) => {
    event.preventDefault();
    navigate(`/pet-profile-edit/${id}`);
    return false;
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
        {translate('pet-medical-records-screen.title-text')}
      </Typography>
      <h4>{translate('pet-medical-records-screen.subtitle-text')}</h4>
      <TextField
        size="small"
        type="text"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <Button variant="contained" type="submit" sx={{ ml: 2 }}>
        {translate('go_uppercase')}
      </Button>
    </form>
  );
}
