import { useState } from "react";
import {
  petPatch,
  deleteAllergiesConditions,
} from "../../utils/back4AppApi";
import {
  KinshipPetProfile,
  BrandedFood,
} from "../../utils/kinshipTypes";
import {
  Table,
  TableBody,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Stack,
  TableContainer,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { translate } from "../../utils/localisation";

interface Props {
  pet?: KinshipPetProfile;
  onAction?: Function;
}

export function Foods({ 
    pet,
    onAction = () => {}, 
}: Props) {
  const [newFoodName, setNewFoodName] = useState("");
  const onFoodAction = async (event: any, ...args: Array<string | null>) => {
    event.preventDefault();
    const actionType = args[0];
    const allergyArg = args[1];
    const actionHasConfirmDialogStep = args[2];
    if(actionHasConfirmDialogStep !== "NEEDS_CONFIRMATION") {
      if(actionType === "ADD") {
        const data = {
          allergies: [{
            allergyName: newFoodName,
            petType: pet?.petType
          }]
        };
        const result = await petPatch(
          pet?.kinshipPetId,
          data
        );
        onAction(result);
      } else if(actionType === "DELETE") {
        const result = await deleteAllergiesConditions(
          pet?.kinshipPetId,
          {
            deletedAllergies: [
              {
                allergyName: allergyArg
              }
            ]
          },
        );
        onAction(result);
      }
    }
  };
  return (
    <Box sx={{ mt: 2, p: 4, border: 1, borderColor: "gray", borderRadius: 5 }}>
    <Grid container sx={{ minWidth: 1200 }}>
      <Grid item xs={6}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: 2, fontWeight: "medium" }}
      >
      {translate("foods")}
      </Typography>
      <TableContainer sx={{ minWidth: 1200 }}>
        <Table sx={{width: "max-content"}}>
        <TableBody>
          {pet?.petFoods?.branded?.map((food: BrandedFood, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell align="left" colSpan={2}>
                    {food?.productName}
                </TableCell>
                <TableCell align="left" colSpan={2}>
                    <Stack direction="row" spacing={1}>
                    <IconButton
                        color="primary"
                        aria-label={translate('delete')}
                        onClick={(event) => onFoodAction(event, "DELETE", food?.productName)}
                    >
                        <DeleteIcon />
                    </IconButton>
                    </Stack>
                </TableCell>
              </TableRow>
            );
            }
        )}
        <TableRow>
            <TableCell align="left" colSpan={2}>
              <TextField
                  id="newName"
                  name="newName"
                  label={translate('new_name')}
                  variant="outlined"
                  size="small"
                  value={newFoodName}
                  onChange={(e) => setNewFoodName(e.target.value)}
              />
            </TableCell>
            <TableCell align="left" colSpan={2}>
              <Stack direction="row" spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(event) => onFoodAction(event, "ADD")}
                  >
                    {translate('add_food')}
                  </Button>
              </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box>
 );
}
