import axios from "axios";

export const getCurrentLocaleSelector = () => {
  const defaultLocaleForApp = 'en-US';
  const systemLocale = localStorage.getItem('currentLocale') ?? navigator.language ?? defaultLocaleForApp;
  let profileLocale =  systemLocale;
  const profile = localStorage.getItem('profile');
  if(profile) {
    profileLocale = JSON.parse(profile)?.locale;
  } else {
    profileLocale = defaultLocaleForApp;
  }
  
  if (profileLocale !== systemLocale) {
    console.warn('Profile locale does not match system locale');
  }
  return profileLocale || systemLocale;
};

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Kinship-Source': 'The Kin',
    'apikey': `${process.env.REACT_APP_KINSHIP_API_KEY}`,
    'Accept-Language': getCurrentLocaleSelector()
  },
  mode: 'no-cors',
  crossdomain: true,
  baseURL: `https://${process.env.REACT_APP_KINSHIP_API_BASE_URL}`,
  timeout: 20000,
});

export const back4app = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Kinship-Source': 'The Kin',
    'apikey': `${process.env.REACT_APP_KINSHIP_API_KEY}`,
    'Accept-Language': getCurrentLocaleSelector()
  },
  mode: 'no-cors',
  crossdomain: true,
  baseURL: `https://${process.env.REACT_APP_KINSHIP_API_BASE_URL}/back4app`,
  timeout: 20000,
});
