import { changeLocaleToMatchProfile, translate } from "./utils/localisation";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  Navigate,
} from "react-router-dom";
import { PetProfileSearch } from './components/pet-profiles/PetProfileSearch';
import { PetProfileView } from "./components/pet-profiles/PetProfileView";
import { PetProfileEdit } from "./components/pet-profiles/PetProfileEdit";
import { UserProfileList } from "./components/user-profiles/UserProfileList";
import { UserProfileView } from "./components/user-profiles/UserProfileView";
import { ROUTE_PATHS } from "./constants";
import { PetProfile, UserProfile } from "./types";
import axiosInstance from "./utils/axiosInstance";

const DRAWER_WIDTH = 240;

type AppContextType = {
  petProfiles: PetProfile[];
  userProfiles: UserProfile[];
  setUserProfiles: (userProfiles: UserProfile[]) => any;

  numberOfResults: number;
  setNumberOfResults: (page: number) => any;
  page: number;
  setPage: (page: number) => any;
};

export const AppContext = React.createContext<AppContextType>({
  petProfiles: [],
  userProfiles: [],
  setUserProfiles: (userProfiles: UserProfile[]) => {},
  numberOfResults: 0,
  setNumberOfResults: (results: number) => {},
  page: 0,
  setPage: (page: number) => {},
});

function App() {
  const { authState, oktaAuth } = useOktaAuth();
  const [petProfiles, setPetProfiles] = useState<PetProfile[]>([]); // eslint-disable-line
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [page, setPage] = useState<number>(0);
  const [numberOfResults, setNumberOfResults] = useState<number>(0);
  let location = useLocation();
  useEffect(() => {
    // check for route
    changeLocaleToMatchProfile(authState);

    if (!authState) {
      return;
    }
    if (
      location.pathname !== "/authorization-code/callback" &&
      !authState?.isAuthenticated
    ) {
      oktaAuth.signInWithRedirect();
    }
  }, [authState]); // eslint-disable-line
  useEffect(() => {
    async function getStuff() {
      changeLocaleToMatchProfile(authState);
      const userFetch = async () => {
        const userProfiles = await axiosInstance.get("/admin/user-profile/", {
          params: {
            pageSize: 5,
            page: 0,
          },
        });
        setUserProfiles(userProfiles.data.profiles);
        setNumberOfResults(userProfiles.data.count);
        return Promise.resolve(userProfiles.data.profiles);
      };
      const userProfiles = userFetch();
      // const petProfiles = petFetch();
      await Promise.allSettled([userProfiles]);
    }
    if (authState?.isAuthenticated) {
      getStuff();
    }
  }, [authState]);

  return (
    <AppContext.Provider
      value={{
        petProfiles,
        userProfiles,
        setUserProfiles: (userProfiles: UserProfile[]) =>
          setUserProfiles(userProfiles),
        numberOfResults,
        setNumberOfResults: (page: number) => setNumberOfResults(page),
        page,
        setPage: (page: number) => setPage(page),
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {translate('header.top-text')}
            </Typography>
            {authState?.isAuthenticated ? (
              <Button
                onClick={() => oktaAuth.signOut()} // signout button
                variant="contained"
                color="error"
              >
                {translate('header.logout-button-text')}
              </Button>
            ) : (
              <Button
                onClick={() => oktaAuth.signInWithRedirect()} // login in button
                variant="contained"
                color="success"
              >
                {translate('home-screen.login-text')}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {authState?.isAuthenticated &&
                Object.keys(ROUTE_PATHS).map((text) => (
                  <NavLink
                    key={`${text}+nav`}
                    // @ts-ignore
                    to={ROUTE_PATHS[text]}
                  >
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        // @ts-ignore
                        selected={ROUTE_PATHS[text] === location.pathname}
                      >
                        <ListItemIcon>
                          <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            text === Object.keys(ROUTE_PATHS)[0] ?
                            translate('user_profiles'): translate('pet_medical_records')
                          } 
                        />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />

          <Routes>
            {/* <Route
              path={ROUTE_PATHS['Photo Contest']}
              element={
                <RequireAuth>
                  <PhotoContestManager />
                </RequireAuth>
              }
            /> */}
            <Route
              path={ROUTE_PATHS["User Profiles"]}
              element={
                <RequireAuth>
                  <UserProfileList />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTE_PATHS['Pet Medical Records']}
              element={
                <RequireAuth>
                  <PetProfileSearch />
                </RequireAuth>
              }
            />
            <Route
              path="/pet-profile-edit/:petId"
              element={
                <RequireAuth>
                  <PetProfileEdit />
                </RequireAuth>
              }
            />
            <Route
              path="/pet-profile/:petId"
              element={
                <RequireAuth>
                  <PetProfileView />
                </RequireAuth>
              }
            />
            <Route
              path="/user-profile/:userId"
              element={
                <RequireAuth>
                  <UserProfileView />
                </RequireAuth>
              }
            />
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <div>{translate('home-screen.logged-in-display-text')}</div>
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <LoggedOutOnly>
                  <div>{translate('home-screen.please-login-text')}</div>
                </LoggedOutOnly>
              }
            />
          </Routes>
        </Box>
      </Box>
    </AppContext.Provider>
  );
}
// @ts-ignore
function LoggedOutOnly({ children }) {
  const { authState } = useOktaAuth();
  let location = useLocation();

  if (!authState) {
    return <div>{translate('home-screen.loading-text')}</div>;
  }

  if (authState.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
// @ts-ignore
function RequireAuth({ children }) {
  const { authState } = useOktaAuth();
  /*
    The AuthState object includes:
    - isAuthenticated: true if the user is considered authenticated. Normally this is true if both an idToken and an accessToken are present in the tokenManager, but this behavior can be overridden if you passed a transformAuthState callback in the configuration.
    - accessToken: the JWT accessToken for the currently authenticated user (if provided by the scopes).
    - idToken: the JWT idToken for the currently authenticated user (if provided by the scopes).
    - error: contains the error returned if an error occurs in the authState evaluation process.
*/
  let location = useLocation();
  if (!authState) {
    return <div>{translate('home-screen.loading-text')}</div>;
  }
  if (!authState.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default App;
