import { useCurrentLocaleSelector as getCurrentLocaleSelector } from './localisation';
import axios from "axios";
import authClient from "../auth";

// import { API_URL } from '../constants';

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-Kinship-Source": "THE_WILDEST_ADMIN",
    'Accept-Language': getCurrentLocaleSelector()
  },
  mode: "no-cors",
  crossdomain: true,
  // baseURL: API_URL,
  baseURL: process.env.REACT_APP_KINTOPIA_API_BASE_PATH,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await authClient.getAccessToken();
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.data.status === 403) {
      authClient.signOut();
    }
    return Promise.reject(error);
  }
);

// consider renaming to authedAxiosInstace or something later on.
export default axiosInstance;
