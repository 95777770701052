import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Avatar, List, ListItem, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { KinshipPetProfile } from "../../utils/kinshipTypes";
import { MedicalRecordView } from "../pet-medical-records/MedicalRecordView";
import { getPetProfile } from "../../utils/back4AppApi";
import { translate } from "../../utils/localisation";

export function PetProfileEdit() {
  const [pet, setPet] = useState<KinshipPetProfile>();

  const { petId } = useParams();

  const fetchPetDetails = useCallback(async () => {
    const extraData = await getPetProfile(petId);
    setPet(extraData.data);
  }, [petId]);

  useEffect(() => {
    fetchPetDetails();
  }, [fetchPetDetails]);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    key: string,
    value: string
  ) => {
    // navigate to route component PetProfile of the petId
    switch (key) {
      case "pack_subscription_id":
        window.location.href = `https://dashboard.stripe.com/test/subscriptions/${value}`;
        break;
      default:
        break;
    }
  };

  // List of keys that have a clickable interaction ex linking to external website
  const clickIndicators = ["pack_subscription_id"];

  return (
    <>
      {!pet && <Alert severity="error">Pet Not found</Alert>}
      <Typography variant="h2" component="h2" sx={{ fontWeight: "medium" }}>
        {translate('pet_info')}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
          p: 4,
          border: 1,
          borderColor: "gray",
          borderRadius: 5,
        }}
      >
        <Avatar src={pet?.profilePic} sx={{ width: 200, height: 200, mr: 2 }} />
        <List>
          <ListItem>{translate('pet_type')}: {pet?.petType}</ListItem>
          <ListItem>{translate('name')}: {pet?.petName}</ListItem>
          <ListItem>{translate('gender')}: {pet?.gender}</ListItem>
          <ListItem>
            {translate('age')}{`: ${pet?.age?.split("/")[0]} years ${pet?.age?.split("/")[1]} months`}
          </ListItem>
          <ListItem>
            {translate('adoption_date')}: {`${new Date(pet?.adoptionDate || "").toLocaleDateString()}`}
          </ListItem>
          <ListItem>
            {translate('spayed_neutered')}: {pet?.spayedOrNeutered ? "yes" : "no"}
          </ListItem>
          <ListItem>
            {translate('breed')}: {pet?.userSelectPrimaryBreed}
          </ListItem>
        </List>
      </Box>
      <MedicalRecordView pet={pet} onActionVaccination={fetchPetDetails} />
      <Typography
        variant="h4"
        component="h4"
        sx={{ my: 5, fontWeight: "medium" }}
      >
        {translate("additional_pet_details")}
      </Typography>
      <TableContainer component={Paper} sx={{ maxWidth: 800 }}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate('attribute')}</TableCell>
              <TableCell align="left">{translate('value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pet
              ? Object.entries(pet).map(([key, value]) => {
                  const clickAble = !!clickIndicators.find(
                    (indicator) => indicator === key && value
                  );
                  if (typeof value != "string" && typeof value != "boolean") {
                    value = JSON.stringify(value);
                  }
                  return (
                    <TableRow
                      key={key}
                      hover={clickAble}
                      onClick={
                        clickAble
                          ? (event) => handleClick(event, key, value as any)
                          : undefined
                      }
                      sx={{
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#e6e6e6",
                        },
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="left">{key}</TableCell>
                      <TableCell align="left">
                        {`${value}`}
                        {clickAble ? <OpenInNewIcon /> : null}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
